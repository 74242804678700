import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import Layout from "./Layout";
import SEO from "../seo";
import { OG_IMAGE } from "../../utils/constants";
import { StoreListing } from "./StoreListing";
import { GET_SWAG_STORE_PRODUCT_LIST } from "../../graphql/queries";

const canonicalUrl = "https://store.hasura.io/";

const Landing = (props) => {
  const [storeData, updateStoreData] = useState(
    props.pageContext.productsData.store
  );

  const { loading, error, data } = useQuery(GET_SWAG_STORE_PRODUCT_LIST);

  useEffect(() => {
    if (!loading && !error) {
      if (data) {
        updateStoreData(data);
      }
    }
  }, [data]);

  return (
    <Layout location={props.location}>
      <SEO
        title="Hasura Swag Store"
        description="Our favourite Hasura designs now in merch"
        meta={OG_IMAGE}
        canonicalLink={canonicalUrl}
      />
      <StoreListing
        location={props.location}
        loading={false}
        error={false}
        data={storeData}
      />
    </Layout>
  );
};

export default Landing;
